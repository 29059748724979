import { toast } from 'react-toastify';
// A prompt modal, when wants to show error
export function showError(text) {
    return toast.error(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

// A prompt modal, when wants to show success
export function showSuccess(text, title = "", needDelay = true) {
    return toast.success(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

// A prompt modal, when wants to show some information
export function showInfo(text, title = "Info", icon = "info") {
    return toast.info('info', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const obj = {
    showSuccess,
    showError,
    showInfo
};
export default obj;
